import { useStore } from '@web/store'
import { computed, ComputedRef } from 'vue'
import { Platform, Position, Placement } from '@web/mapper/BannerMapper'
import { Stream } from '@web/store/types/modules/stream'
import uniq from 'lodash.uniq'

const store = useStore()

function useCommunities () {
  const communities = computed(() => store.getters['community/communities'].filter(c => c.userCommunity || c.type === 'recommended'))

  return {
    communities
  }
}

function filteringBannerByCommunity ({ banner }: { banner: Banner }) {
  const { communities } = useCommunities()
  const communityIds = communities.value
    ?.filter(community => community.type === 'owned')
    ?.map(community => community.id)

  let notDisplayCommunityIds = true
  if (banner.notDisplayCommunityIds?.length) {
    notDisplayCommunityIds = !banner.notDisplayCommunityIds?.some(id => communityIds?.includes(id))
  }

  let displayCommunityIds = true
  if (banner.communityIds?.length) {
    displayCommunityIds = banner.communityIds?.some(id => communityIds?.includes(id))
  }

  return notDisplayCommunityIds && displayCommunityIds
}

function filteringBannerByCourse ({ banner, courseId, courseIds }: { banner: Banner, courseId?: number, courseIds?: number[] }) {
  if (courseId) {
    const notDisplayCourseIds = banner.notDisplayCourseIds?.length ? !banner.notDisplayCourseIds?.includes(courseId) : true
    const displayCourseIds = banner.courseIds?.length ? banner.courseIds?.includes(courseId) : true

    return notDisplayCourseIds && displayCourseIds
  }

  if (courseIds) {
    const notDisplayCourseIds = banner.notDisplayCourseIds?.length ? courseIds.every(cId => !banner.notDisplayCourseIds?.includes(cId)) : true
    const displayCourseIds = banner.courseIds?.length ? courseIds.some(cId => banner.courseIds?.includes(cId)) : true

    return notDisplayCourseIds && displayCourseIds
  }

  return true
}

function filteringBannerByDay ({ day, banner }: { day: ComputedRef<Day | undefined>, banner: Banner }) {
  return banner.stageDayIds?.length && day.value ? banner.stageDayIds?.includes(day.value?.id) : true
}

export function useCoursesBanners () {
  const courseIds = computed(() => store.getters['stream/streams']?.map(stream => stream?.courseId))
  const bannersTop = computed(() => store.getters['banner/desktopCoursesTop']
    .filter(banner =>
      filteringBannerByCourse({ banner, courseIds: courseIds.value }) &&
      filteringBannerByCommunity({ banner })))
  const bannersSidebar = computed(() => store.getters['banner/desktopCoursesSidebar']
    .filter(banner =>
      filteringBannerByCourse({ banner, courseIds: courseIds.value }) &&
      filteringBannerByCommunity({ banner })))
  const bannersBottom = computed(() => store.getters['banner/desktopCoursesBottom']
    .filter(banner =>
      filteringBannerByCourse({ banner, courseIds: courseIds.value }) &&
      filteringBannerByCommunity({ banner })))
  const bannersBeforeCourse = computed(() => store.getters['banner/desktopCoursesBeforeCourse']
    .filter(banner =>
      filteringBannerByCourse({ banner, courseIds: courseIds.value }) &&
      filteringBannerByCommunity({ banner })))
  const bannersCatalog = computed(() => store.getters['banner/mobileCoursesCatalog']
    .filter(banner =>
      filteringBannerByCourse({ banner, courseIds: courseIds.value }) &&
      filteringBannerByCommunity({ banner })))

  return {
    bannersTop,
    bannersSidebar,
    bannersBottom,
    bannersBeforeCourse,
    bannersCatalog,

    bannersPlatforms: Platform,
    bannersPlacements: Placement,
    bannersPosition: Position.courses
  }
}

export function useCalendarBanners ({ stream }: {
  stream: ComputedRef<Stream | undefined>
}) {
  const bannersTop = computed(() => store.getters['banner/desktopCalendarTop']
    .filter(banner =>
      filteringBannerByCourse({ banner, courseId: stream.value?.courseId }) &&
      filteringBannerByCommunity({ banner })))
  const bannersSidebar = computed(() => store.getters['banner/desktopCalendarSidebar']
    .filter(banner =>
      filteringBannerByCourse({ banner, courseId: stream.value?.courseId }) &&
      filteringBannerByCommunity({ banner })))
  const bannersBottom = computed(() => store.getters['banner/desktopCalendarBottom']
    .filter(banner =>
      filteringBannerByCourse({ banner, courseId: stream.value?.courseId }) &&
      filteringBannerByCommunity({ banner })))
  const bannersBeforeStage = computed(() => store.getters['banner/mobileCalendarBeforeStage']
    .filter(banner =>
      filteringBannerByCourse({ banner, courseId: stream.value?.courseId }) &&
      filteringBannerByCommunity({ banner })))

  return {
    bannersTop,
    bannersSidebar,
    bannersBottom,
    bannersBeforeStage,

    bannersPlatforms: Platform,
    bannersPlacements: Placement,
    bannersPosition: Position.calendar
  }
}

export function useDayBanners ({ stream, day }: {
  stream: ComputedRef<Stream | undefined>
  day: ComputedRef<Day | undefined>
}) {
  const bannersTop = computed(() => store.getters['banner/desktopDayTop']
    .filter(banner =>
      filteringBannerByCourse({ banner, courseId: stream.value?.courseId }) &&
      filteringBannerByDay({ day, banner }) &&
      filteringBannerByCommunity({ banner })))
  const bannersBottom = computed(() => store.getters['banner/desktopDayBottom']
    .filter(banner =>
      filteringBannerByCourse({ banner, courseId: stream.value?.courseId }) &&
      filteringBannerByDay({ day, banner }) &&
      filteringBannerByCommunity({ banner })))
  const bannersMobileBottom = computed(() => store.getters['banner/mobileDayBottom']
    .filter(banner =>
      filteringBannerByCourse({ banner, courseId: stream.value?.courseId }) &&
      filteringBannerByDay({ day, banner }) &&
      filteringBannerByCommunity({ banner })))
  const bannerBeforeLessons = computed(() => {
    const _banners = store.getters['banner/desktopDayBeforeLessons']
      .filter(banner =>
        filteringBannerByCourse({ banner, courseId: stream.value?.courseId }) &&
        filteringBannerByDay({ day, banner }) &&
        filteringBannerByCommunity({ banner }))
    if (_banners && _banners.length) {
      return _banners[0]
    }
    return null
  })
  const bannerInsteadVideo = computed(() => {
    const _banners = store.getters['banner/desktopDayInsteadVideo']
      .filter(banner =>
        filteringBannerByCourse({ banner, courseId: stream.value?.courseId }) &&
        filteringBannerByDay({ day, banner }) &&
        filteringBannerByCommunity({ banner }))
    if (_banners && _banners.length) {
      return _banners[0]
    }
    return null
  })
  const bannerMobileInsteadVideo = computed(() => {
    const _banners = store.getters['banner/mobileDayInsteadVideo']
      .filter(banner =>
        filteringBannerByCourse({ banner, courseId: stream.value?.courseId }) &&
        filteringBannerByDay({ day, banner }) &&
        filteringBannerByCommunity({ banner }))
    if (_banners && _banners.length) {
      return _banners[0]
    }
    return null
  })
  const bannerMobileAfterVideo = computed(() => {
    if (bannerMobileInsteadVideo.value) {
      return null
    }
    const _banners = store.getters['banner/mobileDayAfterVideo']
      .filter(banner =>
        filteringBannerByCourse({ banner, courseId: stream.value?.courseId }) &&
        filteringBannerByDay({ day, banner }) &&
        filteringBannerByCommunity({ banner }))
    if (_banners && _banners.length) {
      return _banners[0]
    }
    return null
  })

  return {
    bannersTop,
    bannersBottom,
    bannerBeforeLessons,
    bannerInsteadVideo,
    bannersMobileBottom,
    bannerMobileInsteadVideo,
    bannerMobileAfterVideo,

    bannersPlatforms: Platform,
    bannersPlacements: Placement,
    bannersPosition: Position.day
  }
}

export function useHomeworksBanners () {
  const bannersTop = computed(() => store.getters['banner/desktopHomeworksTop']
    .filter(banner => filteringBannerByCommunity({ banner })))
  const bannersSidebar = computed(() => store.getters['banner/desktopHomeworksSidebar']
    .filter(banner => filteringBannerByCommunity({ banner })))
  const bannersBottom = computed(() => store.getters['banner/desktopHomeworksBottom']
    .filter(banner => filteringBannerByCommunity({ banner })))
  const bannersCatalog = computed(() => store.getters['banner/mobileHomeworksCatalog']
    .filter(banner => filteringBannerByCommunity({ banner })))

  return {
    bannersTop,
    bannersSidebar,
    bannersBottom,
    bannersCatalog,

    bannersPlatforms: Platform,
    bannersPlacements: Placement,
    bannersPosition: Position.homeworks
  }
}

export function useVideoconsultationsBanners () {
  const bannersTop = computed(() => store.getters['banner/desktopVideoconsultationsTop']
    .filter(banner => filteringBannerByCommunity({ banner })))
  const bannersSidebar = computed(() => store.getters['banner/desktopVideoconsultationsSidebar']
    .filter(banner => filteringBannerByCommunity({ banner })))
  const bannersBottom = computed(() => store.getters['banner/desktopVideoconsultationsBottom']
    .filter(banner => filteringBannerByCommunity({ banner })))
  const bannersCatalog = computed(() => store.getters['banner/mobileVideoconsultationsCatalog']
    .filter(banner => filteringBannerByCommunity({ banner })))

  return {
    bannersTop,
    bannersSidebar,
    bannersBottom,
    bannersCatalog,

    bannersPlatforms: Platform,
    bannersPlacements: Placement,
    bannersPosition: Position.videoconsultations
  }
}

export function useSupportBanners () {
  const bannersTop = computed(() => store.getters['banner/desktopSupportTop']
    .filter(banner => filteringBannerByCommunity({ banner })))
  const bannersSidebar = computed(() => store.getters['banner/desktopSupportSidebar']
    .filter(banner => filteringBannerByCommunity({ banner })))
  const bannersBottom = computed(() => store.getters['banner/desktopSupportBottom']
    .filter(banner => filteringBannerByCommunity({ banner })))
  const bannersCatalog = computed(() => store.getters['banner/mobileSupportCatalog']
    .filter(banner => filteringBannerByCommunity({ banner })))

  return {
    bannersTop,
    bannersSidebar,
    bannersBottom,
    bannersCatalog,

    bannersPlatforms: Platform,
    bannersPlacements: Placement,
    bannersPosition: Position.support
  }
}

export function useSettingsBanners () {
  const bannersTop = computed(() => store.getters['banner/desktopSettingsTop']
    .filter(banner => filteringBannerByCommunity({ banner })))
  const bannersSidebar = computed(() => store.getters['banner/desktopSettingsSidebar']
    .filter(banner => filteringBannerByCommunity({ banner })))
  const bannersBottom = computed(() => store.getters['banner/desktopSettingsBottom']
    .filter(banner => filteringBannerByCommunity({ banner })))
  const bannersCatalog = computed(() => store.getters['banner/mobileSettingsCatalog']
    .filter(banner => filteringBannerByCommunity({ banner })))

  return {
    bannersTop,
    bannersSidebar,
    bannersBottom,
    bannersCatalog,

    bannersPlatforms: Platform,
    bannersPlacements: Placement,
    bannersPosition: Position.settings
  }
}

export function useAllCatalogBanners () {
  const mobileCoursesCatalog = computed(() => store.getters['banner/mobileCoursesCatalog']
    .filter(banner => filteringBannerByCommunity({ banner })))
  const mobileHomeworksCatalog = computed(() => store.getters['banner/mobileHomeworksCatalog']
    .filter(banner => filteringBannerByCommunity({ banner })))
  const mobileVideoconsultationsCatalog = computed(() => store.getters['banner/mobileVideoconsultationsCatalog']
    .filter(banner => filteringBannerByCommunity({ banner })))
  const mobileSupportCatalog = computed(() => store.getters['banner/mobileSupportCatalog']
    .filter(banner => filteringBannerByCommunity({ banner })))

  const bannersCatalog = computed(() => {
    return uniq([
      ...mobileCoursesCatalog.value,
      ...mobileHomeworksCatalog.value,
      ...mobileVideoconsultationsCatalog.value,
      ...mobileSupportCatalog.value
    ])
  })

  return {
    bannersCatalog,
    bannersPlatforms: Platform,
    bannersPlacements: Placement
  }
}

export function useHomeBanners () {
  const bannerDesktop = computed(() => {
    const _banners = store.getters['banner/desktopMainPage']
      .filter(banner => filteringBannerByCommunity({ banner }))
    if (_banners && _banners.length) {
      return _banners[0]
    }
    return null
  })
  const bannerMobile = computed(() => {
    const _banners = store.getters['banner/mobileMainPage']
      .filter(banner => filteringBannerByCommunity({ banner }))
    if (_banners && _banners.length) {
      return _banners[0]
    }
    return null
  })

  return {
    bannerDesktop,
    bannerMobile,
    bannerPlatform: Platform,
    bannerPlacement: Placement.mainPage
  }
}
