import { computed } from 'vue'

import { useStore } from '@web/store'
const store = useStore()

function useStreams () {
  const streams = computed(() => store.getters['stream/streams'])
  const isLoadedStreams = computed(() => store.getters['stream/isLoadedAll'])

  async function fetchDataForce () {
    await store.dispatch('stream/fetchUserCourseStreams')
  }

  return {
    streams,
    isLoadedStreams,
    fetchDataForce
  }
}

export default useStreams
