
import { defineComponent, computed } from 'vue'
import AcButton from '@ui-kit/components/AcButton.vue'
import { getUrlWithAuthParams } from '@web/common/Utils'
import { currencyInfo } from '@ui-kit/util'
import { useRoute } from 'vue-router'
import urlParse from 'url-parse'

export default defineComponent({
  name: 'CatalogItem',
  components: {
    AcButton
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const route = useRoute()
    const currency = computed(() => currencyInfo().getCurrency(props.item.price.currencyCode)?.symbol || '¤')

    function goToMore () {
      const queryParams = {
        utm_source: 'lk',
        utm_medium: 'catalog'
      }
      if (route.name === 'home') {
        queryParams.utm_medium = 'catalog_main'
      }
      const urlInstance = urlParse(props.item.link, true)
      urlInstance.set('query', {
        ...urlInstance.query,
        ...queryParams
      })

      window.open(getUrlWithAuthParams(urlInstance.toString()), '_system')
    }

    return {
      currency,
      goToMore
    }
  }
})
