import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "catalog-items"
}
const _hoisted_2 = { class: "catalog-items_wrapper" }
const _hoisted_3 = {
  key: 0,
  class: "catalog-items_actions"
}
const _hoisted_4 = {
  key: 1,
  class: "g-preloader_wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ac_button = _resolveComponent("ac-button")!
  const _component_as_preloader = _resolveComponent("as-preloader")!

  return (_ctx.loaded)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "items", {
            items: _ctx.items,
            class: "catalog-items_items"
          })
        ]),
        (_ctx.isShowMore)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_ac_button, {
                class: "catalog-items_showMore",
                variant: "outlined",
                disabled: _ctx.isDisabledShowMore,
                onClick: _ctx.onShowMore
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('showMore')), 1)
                ]),
                _: 1
              }, 8, ["disabled", "onClick"])
            ]))
          : _createCommentVNode("", true)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_4, [
        _createVNode(_component_as_preloader, { class: "g-preloader" })
      ]))
}