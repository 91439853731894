
import { defineComponent } from 'vue'

import AcModal from '@ui-kit/components/AcModal.vue'
import AsRenewModal from '@web/components/AsRenewModal.vue'

import { Stream } from '@web/store/types/modules/stream'

export default defineComponent({
  name: 'HomeCourseRenewCourse',
  props: {
    stream: {
      type: Object as () => Stream,
      required: true
    }
  },
  emits: ['close'],
  components: {
    AcModal,
    AsRenewModal
  }
})
