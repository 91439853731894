import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_as_renew_modal = _resolveComponent("as-renew-modal")!
  const _component_ac_modal = _resolveComponent("ac-modal")!

  return (_openBlock(), _createBlock(_component_ac_modal, {
    "model-value": true,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_as_renew_modal, {
        class: "renewCourse_modal",
        type: "course",
        streamId: _ctx.stream.id,
        onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
      }, null, 8, ["streamId"])
    ]),
    _: 1
  }))
}