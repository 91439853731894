import { computed } from 'vue'

import { useStore } from '@web/store'
const store = useStore()

function useCourses () {
  const courses = computed(() => store.getters['course/courses'])

  async function fetchDataForce () {
    await store.dispatch('stream/fetchUserCourseStreams')
  }

  return {
    courses,
    fetchDataForce
  }
}

export default useCourses
