
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Event',
  props: {
    event: {
      type: Object,
      required: true
    }
  }
})
