import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2ecd138f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "catalog-item" }
const _hoisted_2 = { class: "catalog-item_column" }
const _hoisted_3 = { class: "catalog-item_row" }
const _hoisted_4 = { class: "catalog-item_preview preview" }
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = { class: "catalog-item_row catalog-item_content" }
const _hoisted_7 = { class: "catalog-item_column" }
const _hoisted_8 = { class: "catalog-item_column" }
const _hoisted_9 = {
  key: 0,
  class: "catalog-item_tag"
}
const _hoisted_10 = { class: "catalog-item_caption" }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = {
  key: 2,
  class: "catalog-item_author"
}
const _hoisted_13 = { class: "catalog-item_column -justify-flex-end" }
const _hoisted_14 = { class: "catalog-item_row catalog-item_price price" }
const _hoisted_15 = { class: "price_new" }
const _hoisted_16 = ["innerHTML"]
const _hoisted_17 = {
  key: 0,
  class: "price_old"
}
const _hoisted_18 = ["innerHTML"]
const _hoisted_19 = { class: "catalog-item_row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ac_button = _resolveComponent("ac-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("img", {
            src: _ctx.item.image,
            alt: _ctx.item.title,
            class: "preview_img"
          }, null, 8, _hoisted_5)
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            (_ctx.item.tag)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.item.tag), 1))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.item.title), 1),
            (_ctx.item.description)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: "catalog-item_description",
                  innerHTML: _ctx.item.description
                }, null, 8, _hoisted_11))
              : _createCommentVNode("", true),
            (_ctx.item.author)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.$t('courseAuthor')) + ": " + _toDisplayString(_ctx.item.author), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, [
                _createTextVNode(_toDisplayString(_ctx.item.price.amount) + " ", 1),
                _createElementVNode("span", { innerHTML: _ctx.currency }, null, 8, _hoisted_16)
              ]),
              (_ctx.item.price.amountOld)
                ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                    _createTextVNode(_toDisplayString(_ctx.item.price.amountOld) + " ", 1),
                    _createElementVNode("span", { innerHTML: _ctx.currency }, null, 8, _hoisted_18)
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_19, [
              _createVNode(_component_ac_button, {
                class: "catalog-item_more",
                onClick: _ctx.goToMore,
                block: ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('More')), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ])
        ])
      ])
    ])
  ]))
}