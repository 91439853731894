
import { computed, defineComponent, onMounted, ref } from 'vue'

import AsPreloader from '@web/components/AsPreloader.vue'

import useCourses from '@web/composition/useCourses'
import useStreams from '@web/composition/useStreams'
import { useCoursesBanners } from '@web/composition/useBanners'
import { Stream } from '@web/store/types/modules/stream'
import StreamStatus from '@web/consts/StreamStatus'
import Swal from 'sweetalert2'
import i18n from '@web/plugins/i18n'
import Logger from '@web/common/Logger'
import sentry from '@web/common/sentry'
import { useStore } from '@web/store'
import { useRoute } from 'vue-router'
import PAGE_NAME from '@web/consts/PAGE_NAME'

export default defineComponent({
  name: 'Courses',
  components: {
    AsPreloader
  },
  emits: ['loaded'],
  setup (props, ctx) {
    const store = useStore()
    const route = useRoute()

    const loaded = ref(false)
    const bannersShown = ref({})

    const {
      courses
    } = useCourses()

    const {
      streams,
      isLoadedStreams
    } = useStreams()

    const {
      bannersBeforeCourse
    } = useCoursesBanners()

    function filteredBannersBeforeCourse (courseId) {
      return bannersBeforeCourse.value.filter(banner => {
        const isBannerForCourse = banner.courseIds.includes(courseId)
        const isBannerForAllCourse = banner.courseIds.length === 0
        const isShowBanner = isBannerForCourse || isBannerForAllCourse

        if (isShowBanner && (!bannersShown.value[banner.id] || bannersShown.value[banner.id] === courseId)) {
          bannersShown.value[banner.id] = courseId
          return true
        }

        return false
      })
    }

    const coursesMapped = computed(() => {
      const _courses: {
        course: Course;
        stream: Stream;
        banners: Banner[];
      }[] = []

      Object.values(streams.value).forEach(stream => {
        _courses.push({
          course: courses.value[stream.courseId],
          stream,
          banners: filteredBannersBeforeCourse(stream.courseId)
        })
      })

      return _courses.sort((a, b) => {
        const order = [StreamStatus.OPEN, StreamStatus.NOT_STARTED, StreamStatus.FREEZE, StreamStatus.STOPPED, StreamStatus.ENDED]
        return order.indexOf(a.stream.status) - order.indexOf(b.stream.status)
      })
    })

    function showErrorAlert () {
      Swal.fire({
        icon: 'error',
        title: i18n.global.t('error.error'),
        text: i18n.global.t('error.courses')
      })
    }
    function showErrorUpdateStreamStatus () {
      Swal.fire({
        icon: 'error',
        title: i18n.global.t('error.error'),
        text: i18n.global.t('error.coursesUpdate')
      })
    }

    async function fetchData () {
      await store.dispatch('stream/fetchUserCourseStreamsCached')

      store.dispatch('stream/fetchUpdateUserStreamsStatus')
        .catch(e => {
          if (route.name === PAGE_NAME.HOME || route.name === PAGE_NAME.COURSES) {
            showErrorUpdateStreamStatus()
          }
          Logger.error(e)
        })
    }

    async function init () {
      try {
        loaded.value = false
        ctx.emit('loaded', false)
        if (isLoadedStreams.value) {
          await fetchData()
        }
      } catch (e) {
        sentry.captureException(e)
        showErrorAlert()
        Logger.error(e)
      } finally {
        loaded.value = true
        ctx.emit('loaded', true)
      }
    }

    onMounted(async () => {
      await init()
    })

    return {
      loaded,
      coursesMapped
    }
  }
})
