import { Stream } from '@web/store/types/modules/stream'
import { getCountDayRemaining } from '@web/common/Utils'
import { daysNoticeRenew, daysShowWarning } from '@web/common/ExternalConfig/getters'
import STREAM_STATUS from '@web/consts/StreamStatus'

export default function (stream: Stream) {
  const countDay = getCountDayRemaining(stream)
  const warningExtendButtonDays = stream.warningExtendButtonDays ? Number(stream.warningExtendButtonDays) : daysShowWarning()
  const criticalExtendButtonDays = stream.criticalExtendButtonDays ? Number(stream.criticalExtendButtonDays) : daysNoticeRenew()
  const isEnded = stream.status === STREAM_STATUS.ENDED || stream.status === STREAM_STATUS.STOPPED
  const isCritical = stream.status === STREAM_STATUS.OPEN && countDay < criticalExtendButtonDays
  const isWarning = !isCritical && (stream.status === STREAM_STATUS.OPEN && countDay < warningExtendButtonDays)
  const isFreezing = stream.status === STREAM_STATUS.FREEZE

  return {
    isEnded,
    isCritical,
    isWarning,
    isFreezing
  }
}
