
import { computed, defineComponent, onMounted, ref } from 'vue'

import AsPreloader from '@web/components/AsPreloader.vue'
import AcButton from '@ui-kit/components/AcButton.vue'

import useCatalog from '@web/composition/useCatalog'

import Logger from '@web/common/Logger'
import sentry from '@web/common/sentry'

export default defineComponent({
  name: 'CatalogItems',
  components: {
    AcButton,
    AsPreloader
  },
  props: {
    preview: {
      type: Boolean,
      default: false
    }
  },
  emits: ['loaded'],
  setup (props, ctx) {
    const loaded = ref(false)
    const currentPage = ref(1)
    const isDisabledShowMore = ref(false)

    const {
      items,
      totalCount,
      fetchData
    } = useCatalog()

    const isShowMore = computed(() => {
      if (props.preview) {
        return false
      }

      return totalCount.value > items.value.length
    })

    async function init () {
      try {
        loaded.value = false
        ctx.emit('loaded', false)
        await fetchData({ perPage: props.preview ? 15 : 6, page: currentPage.value })
      } catch (e) {
        sentry.captureException(e)
        Logger.error(e)
      } finally {
        loaded.value = true
        ctx.emit('loaded', true)
      }
    }

    async function onShowMore () {
      currentPage.value = currentPage.value + 1
      isDisabledShowMore.value = true
      try {
        await fetchData({ page: currentPage.value })
      } catch (e) {
        sentry.captureException(e)
        Logger.error(e)
      } finally {
        isDisabledShowMore.value = false
      }
    }

    onMounted(async () => {
      await init()
    })

    return {
      loaded,
      isShowMore,
      items,
      isDisabledShowMore,
      onShowMore
    }
  }
})
