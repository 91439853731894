import urlParse from 'url-parse'

export function useGetImageWithSize ({ image, width }: { image?: ImageSize, width: 'small' | 'medium' | 'large' | number }): string {
  if (!image || !image.small) {
    return ''
  }

  if (typeof width === 'number') {
    const URLInstance = urlParse(image.small, true)
    URLInstance.set('query', {
      ...URLInstance.query,
      width
    })
    return URLInstance.toString()
  }

  return image[width] || image.small
}

export default useGetImageWithSize
