import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4bada660"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "course_preview preview" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 1,
  class: "preview_img"
}
const _hoisted_4 = { class: "preview_tag" }
const _hoisted_5 = {
  key: 2,
  class: "preview_points"
}
const _hoisted_6 = { class: "course_caption" }
const _hoisted_7 = { class: "course_footer" }
const _hoisted_8 = {
  key: 0,
  class: "course_access"
}
const _hoisted_9 = {
  key: 1,
  class: "course_access"
}
const _hoisted_10 = { class: "course_progress" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_renew_course = _resolveComponent("renew-course")!
  const _component_modal_critical_renew = _resolveComponent("modal-critical-renew")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['course', `${_ctx.page ? '-page' : ''}`]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openCourse && _ctx.openCourse(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.courseImage)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: _ctx.courseImage,
            class: "preview_img",
            loading: "lazy"
          }, null, 8, _hoisted_2))
        : (_openBlock(), _createElementBlock("div", _hoisted_3)),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.course.category), 1),
      (_ctx.pointsText)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.pointsText), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.course.caption), 1),
    _createElementVNode("div", _hoisted_7, [
      (_ctx.isShowAccessText)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createTextVNode(_toDisplayString(_ctx.access.text), 1),
            (_ctx.access.date)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode(": "),
                  _createElementVNode("span", {
                    class: _normalizeClass({ '-critical': _ctx.isCritical, '-warning': _ctx.isWarning})
                  }, _toDisplayString(_ctx.access.date), 3)
                ], 64))
              : _createCommentVNode("", true)
          ]))
        : (_ctx.isShowAccessTextLifeTime)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.$t('subscription.validIndefinitely')), 1))
          : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_10, [
        _createTextVNode(_toDisplayString(_ctx.$t('Progress')) + ": ", 1),
        _createElementVNode("span", null, _toDisplayString(_ctx.progress) + "%", 1)
      ])
    ]),
    (_ctx.showRenewCourse)
      ? (_openBlock(), _createBlock(_component_renew_course, {
          key: 0,
          stream: _ctx.stream,
          onClose: _ctx.onCloseRenewCourse
        }, null, 8, ["stream", "onClose"]))
      : _createCommentVNode("", true),
    (_ctx.showModalCritical)
      ? (_openBlock(), _createBlock(_component_modal_critical_renew, {
          key: 1,
          stream: _ctx.stream,
          course: _ctx.course,
          "onClick:renew": _ctx.onShowRenewModal,
          "onClick:remind": _ctx.remindLater
        }, null, 8, ["stream", "course", "onClick:renew", "onClick:remind"]))
      : _createCommentVNode("", true)
  ], 2))
}