
import { defineComponent, onMounted } from 'vue'

const EVENTS = [
  {
    id: 1,
    title: 'Онлайн-тренировка',
    time: '10:00 - 10:30',
    date: '18 марта 2023',
    description: 'Анастасия Бурдюг разбирает технику выполнения упражнений на онлайн-встрече!\n1234',
    type: 'blue'
  },
  {
    id: 2,
    title: 'Мастер-класс',
    time: '10:00 - 10:30',
    date: '18 марта 2023',
    description: 'Анастасия Бурдюг разбирает технику выполнения упражнений на онлайн-встрече!\n1234',
    type: 'green'
  },
  {
    id: 3,
    title: 'Прямой эфир с Анастасией Бурдюг',
    time: '10:00 - 10:30',
    date: '18 марта 2023',
    description: 'Анастасия Бурдюг разбирает технику выполнения упражнений на онлайн-встрече!\n1234',
    type: 'red'
  }
]

export default defineComponent({
  name: 'Events',
  emits: ['loaded'],
  setup (props, ctx) {
    onMounted(() => ctx.emit('loaded', true))

    return {
      events: EVENTS
    }
  }
})
